export const step1Categories = [
  'Microbiology',
  'Immunology',
  'Histology',
  'Anatomy',
  'Pathology',
  'Pathophysiology',
  'Physiology',
  'Embryology',
  'Biochemistry',
  'Genetics',
  'General Pathology',
  'General Pharmacology',
  'Behavioral sciences',
  'Biostatistics and epidemiology',
  'Ethics',
  'Statistics',
  'Cardiology',
  'Cardiovascular',
  'Endocrine',
  'Musculoskeletal',
  'Neurology',
  'Psychiatry',
  'Gastrointestinal',
  'Heme/Onc',
  'OB/GYN',
  'Renal',
  'Reproductive',
  'Respiratory',
  'Nephrology/Urology',
  'Dermatology',
  'Pulmonology',
  'Hematology/Oncology',
  'Endocrinology',
  'Ophthalmology',
  'Radiology',
]

export const step2Categories = [
  'Ambulatory Medicine',
  'Clinical Neurology',
  'Medicine',
  'Internal Medicine',
  'Surgery',
  'Pediatrics',
  'Obstetrics and Gynecology',
  'Psychiatry',
  'Preventive Medicine',
  'Family Medicine',
  'Emergency Medicine',
  'Radiology',
  'Dermatology',
  'Ophthalmology',
  'Otolaryngology',
  'Orthopedics',
  'Anesthesiology',
  'Geriatrics',
  'Ethics and Legal Issues in Medicine',
  'Ethics',
  'Statistics',
  'Cardiology',
  'Musculoskeletal',
  'Neurology',
  'Gastrointestinal',
  'OB/GYN',
  'Nephrology/Urology',
  'Pulmonology',
  'Hematology/Oncology',
  'Endocrinology',
  'Epidemiology',
]

export const step3Categories = [
  'Internal Medicine',
  'Surgery',
  'Pediatrics',
  'Obstetrics and Gynecology',
  'Psychiatry',
  'Preventive Medicine',
  'Family Medicine',
  'Emergency Medicine',
  'Radiology',
  'Dermatology',
  'Ophthalmology',
  'Otolaryngology',
  'Orthopedics',
  'Anesthesiology',
  'Geriatrics',
  'Ethics and Legal Issues in Medicine',
  'Ethics',
  'Statistics',
  'Cardiology',
  'Musculoskeletal',
  'Neurology',
  'Gastrointestinal',
  'OB/GYN',
  'Nephrology/Urology',
  'Pulmonology',
  'Hematology/Oncology',
  'Endocrinology',
  'Epidemiology',
]
