import React from 'react'
import TesterLayout from 'src/layout/TesterLayout'

const ManageTesterExam = () => {
  return (
    <TesterLayout>
      <div>manage tester exam</div>
    </TesterLayout>
  )
}

export default ManageTesterExam
