import { legacy_createStore as createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  theme: 'light',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store
export const API_URL = 'https://api.zap70.com/'
// export const API_URL = 'http://localhost:8000/'
// export const API_URL = 'http://167.71.95.212:8000/'
