export const step2score = [
  {
    correct: 192,
    score: 180,
  },
  {
    correct: 193,
    score: 181,
  },
  {
    correct: 194,
    score: 182,
  },
  {
    correct: 195,
    score: 183,
  },
  {
    correct: 196,
    score: 184,
  },
  {
    correct: 197,
    score: 185,
  },
  {
    correct: 198,
    score: 186,
  },
  {
    correct: 199,
    score: 187,
  },
  {
    correct: 200,
    score: 188,
  },
  {
    correct: 201,
    score: 189,
  },
  {
    correct: 202,
    score: 190,
  },
  {
    correct: 203,
    score: 191,
  },
  {
    correct: 204,
    score: 192,
  },
  {
    correct: 205,
    score: 193,
  },
  {
    correct: 206,
    score: 194,
  },
  {
    correct: 207,
    score: 195,
  },
  {
    correct: 208,
    score: 196,
  },
  {
    correct: 209,
    score: 197,
  },
  {
    correct: 210,
    score: 198,
  },
  {
    correct: 211,
    score: 199,
  },
  {
    correct: 212,
    score: 200,
  },
  {
    correct: 213,
    score: 201,
  },
  {
    correct: 214,
    score: 202,
  },
  {
    correct: 215,
    score: 203,
  },
  {
    correct: 216,
    score: 204,
  },
  {
    correct: 217,
    score: 205,
  },
  {
    correct: 218,
    score: 206,
  },
  {
    correct: 219,
    score: 207,
  },
  {
    correct: 220,
    score: 208,
  },
  {
    correct: 221,
    score: 209,
  },
  {
    correct: 222,
    score: 210,
  },
  {
    correct: 223,
    score: 211,
  },
  {
    correct: 224,
    score: 212,
  },
  {
    correct: 225,
    score: 213,
  },
  {
    correct: 226,
    score: 214,
  },
  {
    correct: 227,
    score: 215,
  },

  {
    correct: 228,
    score: 216,
  },
  {
    correct: 229,
    score: 217,
  },
  {
    correct: 230,
    score: 218,
  },
  {
    correct: 231,
    score: 219,
  },
  {
    correct: 232,
    score: 220,
  },
  {
    correct: 233,
    score: 221,
  },
  {
    correct: 234,
    score: 222,
  },
  {
    correct: 235,
    score: 223,
  },
  {
    correct: 236,
    score: 224,
  },
  {
    correct: 237,
    score: 225,
  },
  {
    correct: 238,
    score: 226,
  },
  {
    correct: 239,
    score: 227,
  },
  {
    correct: 240,
    score: 228,
  },
  {
    correct: 241,
    score: 229,
  },
  {
    correct: 242,
    score: 230,
  },
  {
    correct: 243,
    score: 231,
  },
  {
    correct: 244,
    score: 232,
  },
  {
    correct: 245,
    score: 233,
  },
  {
    correct: 246,
    score: 234,
  },
  {
    correct: 247,
    score: 235,
  },
  {
    correct: 248,
    score: 236,
  },
  {
    correct: 249,
    score: 237,
  },
  {
    correct: 250,
    score: 238,
  },
  {
    correct: 251,
    score: 239,
  },
  {
    correct: 252,
    score: 240,
  },
  {
    correct: 253,
    score: 241,
  },
  {
    correct: 254,
    score: 242,
  },
  {
    correct: 255,
    score: 243,
  },
  {
    correct: 256,
    score: 252,
  },
  {
    correct: 257,
    score: 253,
  },
  {
    correct: 258,
    score: 254,
  },
  {
    correct: 259,
    score: 255,
  },
  {
    correct: 260,
    score: 256,
  },
  {
    correct: 261,
    score: 257,
  },
  {
    correct: 262,
    score: 258,
  },
  {
    correct: 263,
    score: 259,
  },
  {
    correct: 264,
    score: 260,
  },
  {
    correct: 265,
    score: 261,
  },
  {
    correct: 266,
    score: 262,
  },
  {
    correct: 267,
    score: 263,
  },
  {
    correct: 268,
    score: 264,
  },
  {
    correct: 269,
    score: 265,
  },
  {
    correct: 270,
    score: 266,
  },
  {
    correct: 271,
    score: 267,
  },
  {
    correct: 272,
    score: 268,
  },
  {
    correct: 273,
    score: 269,
  },
  {
    correct: 274,
    score: 270,
  },
  {
    correct: 275,
    score: 271,
  },
  {
    correct: 276,
    score: 272,
  },
  {
    correct: 277,
    score: 273,
  },
  {
    correct: 278,
    score: 274,
  },
  {
    correct: 279,
    score: 275,
  },
  {
    correct: 280,
    score: 276,
  },
  {
    correct: 281,
    score: 277,
  },
  {
    correct: 282,
    score: 278,
  },
  {
    correct: 283,
    score: 279,
  },
  {
    correct: 284,
    score: 280,
  },
  {
    correct: 285,
    score: 281,
  },
  {
    correct: 286,
    score: 282,
  },
  {
    correct: 287,
    score: 283,
  },
  {
    correct: 288,
    score: 284,
  },
  {
    correct: 289,
    score: 285,
  },
  {
    correct: 290,
    score: 286,
  },
  {
    correct: 291,
    score: 287,
  },
  {
    correct: 292,
    score: 288,
  },
  {
    correct: 293,
    score: 289,
  },
  {
    correct: 294,
    score: 290,
  },
  {
    correct: 295,
    score: 291,
  },
  {
    correct: 296,
    score: 292,
  },
  {
    correct: 297,
    score: 293,
  },
  {
    correct: 298,
    score: 294,
  },
  {
    correct: 299,
    score: 295,
  },
  {
    correct: 300,
    score: 296,
  },
  {
    correct: 301,
    score: 297,
  },
  {
    correct: 302,
    score: 298,
  },
  {
    correct: 303,
    score: 299,
  },
  {
    correct: 304,
    score: 299,
  },
  {
    correct: 305,
    score: 299,
  },
  {
    correct: 306,
    score: 299,
  },
  {
    correct: 307,
    score: 299,
  },
  {
    correct: 308,
    score: 299,
  },
  {
    correct: 309,
    score: 299,
  },
  {
    correct: 310,
    score: 299,
  },
  {
    correct: 311,
    score: 299,
  },
  {
    correct: 312,
    score: 299,
  },
  {
    correct: 313,
    score: 299,
  },
  {
    correct: 314,
    score: 299,
  },
  {
    correct: 315,
    score: 299,
  },
  {
    correct: 316,
    score: 299,
  },
  {
    correct: 317,
    score: 299,
  },
  {
    correct: 318,
    score: 299,
  },
  {
    correct: 319,
    score: 299,
  },
  {
    correct: 320,
    score: 300,
  },
]
